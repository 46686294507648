@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f5f9;
}

body.dark {
  background: #000423;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-player {
  width: 365px !important;
  height: 697px !important;
  border: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .react-player {
    width: 300px !important;
    height: 573px !important;
    border-color: rgb(161 161 170);
  }
}